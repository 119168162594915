import axios from "axios";
import mock from "./mock/api";
import "../firebase_config";
import { initFirebaseAuth } from "../firebase_config";

let base = "";
if (JSON.parse(process.env.VUE_APP_ENDPOINT_USE_PROD)) {
  base = "https://social-marketing.io/api/v1";
} else if (JSON.parse(process.env.VUE_APP_ENDPOINT_USE_STG)) {
  base = "https://stg.social-marketing.io/api/v1";
} else {
  base = "http://127.0.0.1:8000/api/v1";
}
export const client = axios.create({
  baseURL: base,
});

client.interceptors.request.use(async (config) => {
  const user = await initFirebaseAuth();
  const token = await user.getIdToken(false);
  config.headers = { Authorization: token };
  return config;
});

// モック起動
if (JSON.parse(process.env.VUE_APP_USE_MOCK)) {
  mock.run(client);
}

export default {
  getAccounts(params) {
    return client.get("/accounts", { params: params });
  },
  createAccount(params) {
    return client.post("/accounts", params);
  },
  createOrganizer(params) {
    return client.post("/organizers", params);
  },
  getMyOrganizer(params) {
    return client.get("/organizers/me", params);
  },
  getOrganizerByInvite(params) {
    return client.get("/organizers/invite/" + params.invite_code, params);
  },
  getCampaigns(params) {
    return client.get("/campaigns", { params: params });
  },
  getCampaign(params) {
    return client.get("/campaigns/" + params.id);
  },
  getCampaignTweets(params) {
    console.log("/campaigns/" + params.id + "/tweets");
    return client.get("/campaigns/" + params.id + "/tweets");
  },
  getdailyTweetCount(params) {
    return client.get("/tweets/dailyCount", { params: params });
  },
  getScore(params) {
    return client.get("/campaigns/score", { params: params });
  },
  getRanking(params) {
    return client.get("/campaigns/ranking", { params: params });
  },
  createCampaign(params) {
    return client.post("/campaigns", params);
  },
  updateCampaign(params) {
    return client.patch("/campaigns/" + params.id, params);
  },
  deleteCampaign(params) {
    return client.patch("/campaigns/" + params.id + "/delete", params);
  },
  createMultiple(params) {
    return client.post("/multiplied-scores", params);
  },
  getMultiple(params) {
    return client.get("/multiplied-scores/" + params.id);
  },
  deleteMultiple(params) {
    return client.delete("/multiplied-scores/" + params.multiplied_score_id);
  },
  checkKeyword(params) {
    return client.post("/twitter/check-keyword", params);
  },
  checkSubscribe(params) {
    return client.get("/organizers/check-subscribe", params);
  },
  checkTwitterAccount(params) {
    return client.post("/twitter/check-account", params);
  },
  checkTweet(params) {
    return client.post("/twitter/check-tweet", params);
  },
  createConditions(params) {
    return client.post(
      "/campaigns/" + params.campaign_id + "/conditions",
      params
    );
  },
  getConditions(params) {
    console.log(params);
    return client.get("/campaigns/" + params.campaign_id + "/conditions", {
      params: params,
    });
  },
  getAllConditions(params) {
    return client.get("/campaigns/all-conditions", params);
  },
  deletefollowConditions(params) {
    return client.delete(
      "/campaigns/" +
        params.campaign_id +
        "/conditions/follow/" +
        params.user_id,
      params
    );
  },
  deleteretweetConditions(params) {
    return client.delete(
      "/campaigns/" +
        params.campaign_id +
        "/conditions/retweet/" +
        params.tweet_id,
      params
    );
  },
  deletediscordConditions(params) {
    return client.delete(
      "/campaigns/" +
        params.campaign_id +
        "/conditions/discord/" +
        params.guild_id,
      params
    );
  },
  deleteWalletConditions(params) {
    return client.delete(
      "/campaigns/" + params.campaign_id + "/conditions/wallet",
      params
    );
  },
  deleteExternalSiteConditions(params) {
    return client.delete(
      "/campaigns/" + params.campaign_id + "/conditions/external-site-visited",
      params
    );
  },
  deleteInstagramFollowConditions(params) {
    return client.delete(
      "/campaigns/" + params.campaign_id + "/conditions/instagram-follow",
      params
    );
  },
  deleteInstagramLikeConditions(params) {
    return client.delete(
      "/campaigns/" + params.campaign_id + "/conditions/instagram-like",
      params
    );
  },
  deleteInstagramHashtagConditions(params) {
    return client.delete(
      "/campaigns/" + params.campaign_id + "/conditions/instagram-hashtag",
      params
    );
  },
  updateCampaignForReady(params) {
    return client.post("/campaigns/" + params.campaign_id + "/ready", params);
  },
  createGifts(params) {
    return client.post("/campaigns/" + params.campaign_id + "/gifts", params);
  },
  getGifts(params) {
    return client.get("/campaigns/" + params.campaign_id + "/gifts", params);
  },
  deleteGifts(params) {
    return client.delete("/campaigns/" + params.campaign_id + "/gifts", params);
  },
  updateGifts(params) {
    return client.patch("/campaigns/" + params.campaign_id + "/gifts/", params);
  },
  getParticipants(params) {
    return client.get(
      "/campaigns/" + params.campaign_id + "/participants",
      params
    );
  },
  changeWinner(params) {
    return client.post(
      "/campaigns/" + params.campaign_id + "/paticipants/change-winner",
      params
    );
  },
  confirmGifts(params) {
    return client.post(
      "/campaigns/" + params.campaign_id + "/gifts/comfirm",
      params
    );
  },
  getInstantWin(params) {
    return client.get(
      "/campaigns/" + params.campaign_id + "/instantwin",
      params
    );
  },
  getInstantWins(params) {
    return client.get("/campaigns/instantwins", params);
  },
  postInstantWin(params) {
    console.log(params);
    return client.post(
      "/campaigns/" + params.campaign_id + "/instantwin",
      params
    );
  },
  postItemsDiscord(params) {
    return client.post("/organizers/items/discord", params);
  },
  getItemsDiscord(params) {
    return client.get("/organizers/items/discord", params);
  },
  deleteItemsDiscord(params) {
    return client.delete("/organizers/items/discord", params);
  },
  updateInstantWin(params) {
    return client.patch(
      "/campaigns/" + params.campaign_id + "/instantwin",
      params
    );
  },
  uploadHeader(params, config) {
    return client.post(
      "/campaigns/" + params.campaign_id + "/upload/header",
      params.form,
      config
    );
  },
  uploadWin(params, config) {
    return client.post(
      "/campaigns/" + params.campaign_id + "/upload/win",
      params.form,
      config
    );
  },
  uploadLose(params, config) {
    return client.post(
      "/campaigns/" + params.campaign_id + "/upload/lose",
      params.form,
      config
    );
  },
  getDiscordSummary(params) {
    return client.get(
      "/discord/summary" +
        "?data_from=" +
        params.data_from +
        "&data_to=" +
        params.data_to
    );
  },
  getDiscordMembers(params) {
    return client.get(
      "/discord/members" +
        "?data_from=" +
        params.data_from +
        "&data_to=" +
        params.data_to
    );
  },
  getDiscordChannels(params) {
    return client.get(
      "/discord/channels" +
        "?data_from=" +
        params.data_from +
        "&data_to=" +
        params.data_to
    );
  },
  getInstantwinDailydata(params) {
    return client.get(
      "/campaigns/" + params.campaign_id + "/instantwin/kpi",
      {}
    );
  },
  getFollowandretweetDailydata(params) {
    return client.get(
      "/campaigns/" + params.campaign_id + "/follow-and-retweet/kpi",
      {}
    );
  },
  getFollowersHistory(params) {
    return client.get(
      "/campaigns/" + params.campaign_id + "/followers-history",
      {}
    );
  },
  updateUserHistory(id, params) {
    return client.patch(`/user-history/${id}`, params);
  },
  getQuests(params) {
    return client.get("/quests", { params: params });
  },
  updateQuest(params) {
    return client.patch("/quests/" + params.quest_id, params);
  },
  deleteQuest(questId) {
    return client.delete("/quests/" + questId);
  },
  postQuest(params) {
    return client.post("/quests", params);
  },
  postVoteOptions(questId, params) {
    return client.post(`/quests/${questId}/vote-options`, params);
  },
  // クイズの詳細を取得するAPI
  getQuizDetails(questId) {
    return client.get(`/quiz-quests/${questId}`);
  },
  // クイズの正解を確定するAPI
  confirmQuizAnswer(questId, correctOption) {
    return client.post(`/quiz-quests/${questId}/answer`, {
      correct_option: correctOption,
    });
  },
  // クイズの正解を取り消すAPI
  revertQuizAnswer(questId) {
    return client.post(`/quiz-quests/${questId}/revert-answer`);
  },
  fetchQuizzes(params) {
    return client.get(`/quiz-quests`, params);
  },
  uploadHeaderImage(formData) {
    return client.post("/app/upload/quest-header", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getHeaderImages() {
    return client.get("/app/quest-headers");
  },
  uploadQuizHeaderImage(formData) {
    return client.post("/app/upload/quiz-header", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getQuizHeaderImages() {
    return client.get("/app/quiz-headers");
  },
  uploadAnnouncementHeaderImage(formData) {
    return client.post("/app/upload/announcement-header", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getAnnouncementHeaderImages() {
    return client.get("/app/announcement-headers");
  },
  sendBulkPushNotification(params) {
    return client.post("/send-push/all", params);
  },

  sendPushNotification(params) {
    return client.post("/send-push", params);
  },
  // お知らせ一覧を取得
  getAnnouncements() {
    return client.get("/announcements");
  },

  // 特定のお知らせを取得
  getAnnouncement(id) {
    return client.get(`/announcements/${id}`);
  },

  // 新しいお知らせを作成
  createAnnouncement(params) {
    return client.post("/announcements", params);
  },

  // お知らせを更新
  updateAnnouncement(id, params) {
    return client.patch(`/announcements/${id}`, params);
  },

  // お知らせを削除（論理削除）
  deleteAnnouncement(id) {
    return client.delete(`/announcements/${id}`);
  },

  downloadCampaignDataCsv() {
    return client
      .get("/campaign-data?format=csv", {
        responseType: "blob", // レスポンスをBlobとして受け取る
      })
      .then((response) => {
        // Blobからファイルを作成
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        // ダウンロードリンクを作成してクリック
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "campaign_data.csv";
        document.body.appendChild(a);
        a.click();

        // クリーンアップ
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      });
  },
};
