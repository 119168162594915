<template>
  <div class="campaign_data_download">
    <SideVar />
    <RightColumnOutline>
      <PageTitle>データダウンロード</PageTitle>
      <div class="flex justify-center items-center h-64">
        <DoButton
          :clickFunction="downloadCampaignDataCsv"
          :disabled="isDownloading"
        >
          抽選データダウンロード
        </DoButton>
      </div>
    </RightColumnOutline>
  </div>
</template>

<script>
import { ref } from "vue";
import api from "../api/api.js";

export default {
  name: "CampaignDataDownload",
  setup() {
    const isDownloading = ref(false);

    const downloadCampaignDataCsv = async () => {
      isDownloading.value = true;
      try {
        await api.downloadCampaignDataCsv();
        console.log("CSV download successful");
      } catch (error) {
        console.error("Error downloading CSV:", error);
      } finally {
        isDownloading.value = false;
      }
    };

    return {
      isDownloading,
      downloadCampaignDataCsv,
    };
  },
};
</script>
