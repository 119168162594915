import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

// コーポレート
import AboutUsView from "../views/Corporates/AboutUs";
import LandingPageView from "../views//Corporates/LandingPage";
import SpecialCommercialLaw from "../views/Corporates/SpecialCommercialLaw.vue";
import TermOfService from "../views/Corporates/TermOfService.vue";
import PrivacyPolicy from "../views/Corporates/PrivacyPolicy.vue";
import LoginView from "../views/Corporates/LoginPage.vue";
import SignupView from "../views//Corporates/SignupPage.vue";
import MailSentView from "../views/Corporates/MailSent.vue";
import PasswordResetView from "../views/Corporates/PasswordReset.vue";
import CompanyDetailView from "../views/Corporates/CompanyDetail.vue";
import ContactToSociama from "../views/Corporates/ContactToSociama.vue";

// サービスページ
import PlansView from "../views/SubscribePlans.vue";
import CampaignListsView from "../views/CampaignLists.vue";
import CampaignCreateView from "../views/CampaignCreate.vue";
import CampaignDetailView from "../views/CampaignDetail.vue";
import ModifyCampaignView from "../views/CampaignModify.vue";
import CampaignAnalisysView from "../views/CampaignAnalitics";
import CampaignAnalisysFaRView from "../views/CampaignAnaliticsFaR";
import CampaignAnalisysITWView from "../views/CampaignAnaliticsITW";
import CampaignAddOptionsView from "../views/CampaignAddOptions";
import CampaignModifyConditions from "../views/CampaignModifyConditions";
import CampaignModifyConditionsByPanel from "../views/CampaignModifyConditionsByPanel.vue";
import CampaignPageCreateView from "../views/CampaignPageCreate";
import ExternalConnectPageView from "../views/ExternalConnect.vue";
import DiscordBotAddedPageView from "../views/DiscordBotAdded.vue";
import DiscordSummaryPageView from "../views/DiscordSummary";
import DiscordChannelsPageView from "../views/DiscordChannels";
import DiscordMembersPageView from "../views/DiscordMembers.vue";
import OrganizerConfigView from "../views/OrganizerConfig.vue";
import CampaignUserHistoryUpdateView from "../views/CampaignUserHistoryUpdate.vue";
import DataDownloadView from "../views/DataDownload.vue";

// app系
import AppQuestListView from "../views/AppQuestList.vue";
import CreateQuestView from "../views/AppCreateQuest.vue";
import QuizAnswerView from "../views/AppQuizAnswer.vue";
import PushNotifications from "../views/AppPushNotifications.vue";
import AnnouncementListView from "../views/AppAnnouncementList.vue";
import CreateAnnouncementView from "../views/AppCreateAnnouncement.vue";
import UpdateAnnouncementView from "../views/AppUpdateAnnouncement.vue";

const routes = [
  {
    path: "/setting/external-connect",
    name: "externalConnect",
    component: ExternalConnectPageView,
    meta: { requiresAuth: true },
  },
  {
    path: "/setting/external-connect/discord-bot-added",
    name: "discordBotAdded",
    component: DiscordBotAddedPageView,
    meta: { requiresAuth: true },
  },
  {
    path: "/discord/summary",
    name: "discordSummary",
    component: DiscordSummaryPageView,
    meta: { requiresAuth: true },
  },
  {
    path: "/discord/channels",
    name: "discordChannels",
    component: DiscordChannelsPageView,
    meta: { requiresAuth: true },
  },
  {
    path: "/discord/members",
    name: "discordMembers",
    component: DiscordMembersPageView,
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns/analisys/:id",
    name: "analisys",
    component: CampaignAnalisysView,
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns/analisys/follow-and-retweet/:id",
    name: "analisysFaR",
    component: CampaignAnalisysFaRView,
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns/analisys/instantwin/:id",
    name: "analisysITW",
    component: CampaignAnalisysITWView,
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns/analisys/instantwin/:id/modify-user-history",
    name: "modifyUserHistory",
    component: CampaignUserHistoryUpdateView,
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns/add-options/:id",
    name: "addOptions",
    component: CampaignAddOptionsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns/add-conditions/:id",
    name: "addConditions",
    component: CampaignModifyConditions,
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns/add-conditions-by-panel/:id",
    name: "addConditionsByPanel",
    component: CampaignModifyConditionsByPanel,
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns/:id/instant-win/create-page",
    name: "createCampaignPage",
    component: CampaignPageCreateView,
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    name: "landing",
    component: LandingPageView,
    meta: { requiresAuth: false },
  },
  {
    path: "/about-us",
    name: "about",
    component: AboutUsView,
    meta: { requiresAuth: false },
  },
  {
    path: "/special-commercial-law",
    name: "scl",
    component: SpecialCommercialLaw,
    meta: { requiresAuth: false },
  },
  {
    path: "/term-of-service",
    name: "tos",
    component: TermOfService,
    meta: { requiresAuth: false },
  },
  {
    path: "/privacy-policy",
    name: "pp",
    component: PrivacyPolicy,
    meta: { requiresAuth: false },
  },
  {
    path: "/company-detail",
    name: "companyDetail",
    component: CompanyDetailView,
    meta: { requiresAuth: false },
  },
  {
    path: "/contact",
    name: "contactToSociama",
    component: ContactToSociama,
    meta: { requiresAuth: false },
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: PasswordResetView,
    meta: { requiresAuth: false },
  },
  {
    path: "/sent-mail",
    name: "mailsent",
    component: MailSentView,
    meta: { requiresAuth: false },
  },
  {
    path: "/campaigns",
    name: "campaigns",
    component: CampaignListsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/campaign/:id",
    name: "campaignDetail",
    component: CampaignDetailView,
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns/modify/:id",
    name: "modifyCampaign",
    component: ModifyCampaignView,
    meta: { requiresAuth: true },
  },

  {
    path: "/sign-up",
    name: "signUp",
    component: SignupView,
  },
  {
    path: "/create-campaign",
    name: "createCampaign",
    component: CampaignCreateView,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "logIn",
    component: LoginView,
    beforeEnter: (to, from, next) => {
      if (store.getters["user"]) {
        next({
          path: "/campaigns",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/subscribe",
    name: "subscribePlans",
    component: PlansView,
    meta: { requiresAuth: true },
  },
  {
    path: "/config",
    name: "organizerConfig",
    component: OrganizerConfigView,
    meta: { requiresAuth: true },
  },
  {
    path: "/quests",
    name: "AppQuests",
    component: AppQuestListView,
    meta: { requiresAuth: true },
  },
  {
    path: "/create-quest",
    name: "CreateQuest",
    component: CreateQuestView,
    meta: { requiresAuth: true },
  },
  {
    path: "/quiz-answer",
    name: "QuizAnswer",
    component: QuizAnswerView,
    meta: { requiresAuth: true },
  },
  {
    path: "/push-notifications",
    name: "PushNotifications",
    component: PushNotifications,
    meta: { requiresAuth: true },
  },
  {
    path: "/announcements",
    name: "Announcements",
    component: AnnouncementListView,
    meta: { requiresAuth: true },
  },
  {
    path: "/create-announcement",
    name: "CreateAnnouncement",
    component: CreateAnnouncementView,
    meta: { requiresAuth: true },
  },
  {
    path: "/update-announcement/:id",
    name: "UpdateAnnouncement",
    component: UpdateAnnouncementView,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/data-download",
    name: "DataDownload",
    component: DataDownloadView,
    meta: { requiresAuth: true },
    props: true,
  },
];

// routerで遷移した際にページトップに戻す処理
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0 };
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth) {
    if (!store.getters["user"]) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
